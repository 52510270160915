import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useAppSelector } from '../../../store/hooks';
import { selectUserInformation } from '../../../store/reducers';
import type { Appointment } from '../../../types/ninox.types';
import { Button, Headline, Icon } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './OfflineFirstAppointmentSuccess.module.scss';

const cx = classNames.bind(classes);

export function OfflineFirstAppointmentSuccess() {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [appointmentData, setAppointmentData] = useState<Appointment | undefined>(undefined);

  const offlineFirstAppointmentClasses = cx({ OfflineFirstAppointment: true });
  const stepsOfflineFirstAppointmentClasses = cx({ OfflineFirstAppointmentSuccess: true, StepsContainer: true });
  const appointmentSuccessContainerClasses = cx({ AppointmentSuccessContainer: true });
  const appointmentSuccessMainClasses = cx({ AppointmentSuccessMain: true });
  const appointmentSuccessInfoClasses = cx({ AppointmentSuccessInfo: true });
  const buttonConatinerClasses = cx({ ButtonContainer: true });

  const userInformation = useAppSelector(selectUserInformation);

  useEffect(() => {
    if (userInformation.appointment) {
      const appointmentId = parseInt(`${searchParams?.get('appointmentId')}`, 10);
      setAppointmentData(
        userInformation.appointment.find((appointment: Appointment) => parseInt(appointment.id, 10) === appointmentId)
      );
    }
  }, [userInformation]);

  if (!appointmentData) {
    return (
      <React.Fragment>
        <Header />
        <Headline level={3}>Diese Seite ist nicht mehr gültig.</Headline>
        <div className={classes['button-container']}>
          <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header showBackButton={true} barTitle='Erstgespräch buchen' />
      <div className={offlineFirstAppointmentClasses}>
        <div className={stepsOfflineFirstAppointmentClasses}>
          <Icon icon={'checkCircleFilled'} size={80} color={'#3db384'} />
          <Headline level={3}>Ihr Termin ist gebucht!</Headline>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessMainClasses}>
              Ihr Termin am{' '}
              {appointmentData &&
                new Date(appointmentData.startDate).toLocaleString('de-DE', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}{' '}
              Uhr wurde erfolgreich gebucht. Eine Bestätigung wurde Ihnen soeben per E-Mail übermittelt.
            </div>
          </div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessInfoClasses}>
              Mit der E-Mail erhalten Sie ebenfalls den Aufklärungsbogen und weitere wichtige Informationen zu Ihrem
              Termin.
            </div>
          </div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessInfoClasses}>
              Auf Ihrem Dashboard werden Ihnen alle weiteren Informationen angezeigt.
            </div>
          </div>
          <div className={buttonConatinerClasses}>
            <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
