import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
};

// eslint-disable-next-line import/no-default-export
export default class AppointmentService {
  static async createAppointment(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/appointment/patient', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllAppointments(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/appointment/patient', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAppointmentById(id: string | number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/user/appointment/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
